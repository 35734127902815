@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100%;
}

body {
  //overflow-y: scroll;
}

body.reveal-viewport > #__next {
  height: 100%;
}
